







































































































import Vue, { VueConstructor } from 'vue'
import { Key } from 'ts-key-enum';
import { KeypressEvent } from '@/models/interfaces/events/Keyboard'
import Textbox from './Textbox.vue'
import { PlayItem } from '@/models/PlayItem';
import { mapActions, mapGetters } from 'vuex';
import { Recharge } from '@/models/interfaces/Recharge';
var timeoutKey:any = null;

export default (Vue as VueConstructor<Vue & {
  $refs: any,
}>).extend({
  name: 'keyboard',
  props: ['value', 'loading'],
  components: { Textbox },
  computed: {
    ...mapGetters('sales', ['selectedOperator']),
    playitemStringFormated(): string {
      return this.phoneMask(this.phoneText);
    },
  },
  data() {
    return {
      phoneText: '',
      amountText: '',
      showed: this.value,
      activeTextbox: 'playtextbox',
      lotteryAnimated: false,
      selectedItem: 0,
      items: [0,5,10],
    };
  },
  mounted() {
    this.focusActiveTextbox();
  },
  methods: {
    ...mapActions('sales', ['clearSelectedOperator']),
    clearFields() {
      this.phoneText =  '';
      this.amountText = '';
      this.selectedItem = 0;
    },
    handlePlayBtnClick(e: MouseEvent) {
      if (this.loading) return;
      const el = (e.target as HTMLDivElement);
      const prepareEvent : KeypressEvent = { key: '{finish}', target: el };
      this.$emit('keypress', prepareEvent);
    },
    handleClick(e: Event) {
      const el = (e.target as HTMLDivElement);
      const btnClicked = el.getAttribute('data-key');
      this.focusActiveTextbox();
      if (btnClicked == null) return;

      const prepareEvent : KeypressEvent = { key: btnClicked, target: el };

      this.$emit('keypress', prepareEvent);

      const numbers = ['1','2','3','4','5','6','7','8','9','0'];

      if (this.activeTextbox && numbers.includes(btnClicked)) {
        const newEvent = new KeyboardEvent("keydown", {bubbles : true, cancelable : true, key : btnClicked, shiftKey : false});
        this.$refs[this.activeTextbox]?.handleKeydown(newEvent);
      }
      if (btnClicked == '{hide}') {
        this.$emit('keyboard-close');
      }
      if (btnClicked == '{bksp}') {
        this.$emit('keyboard-show');
        const newEvent = new KeyboardEvent("keydown", {bubbles : true, cancelable : true, key : Key.Backspace, shiftKey : false});
        this.$refs[this.activeTextbox]?.handleKeydown(newEvent);
      }
      if(btnClicked == '{clear}') {
        this.clearFields();
        this.clearSelectedOperator();
      }
      if (btnClicked == '{tab}') {
        const newEvent = new KeyboardEvent("keydown", {bubbles : true, cancelable : true, key : Key.Tab, shiftKey : false});
        this.$refs[this.activeTextbox]?.handleKeydown(newEvent);
      }
    },
    handlePlayTextboxClick() {
      this.openKeyboard();
    },
    handleAmountTextboxClick() {
      this.openKeyboard();
    },
    handleFocusPlayTextbox() {
      this.activeTextbox = 'playtextbox';
    },
    handleFocusAmountTextbox() {
      this.activeTextbox = 'amounttextbox';
    },
    openKeyboard() {
      if (this.value) return;
      this.$emit('input', true);
    },
    focusActiveTextbox() {
      this.$refs[this.activeTextbox]?.$el.focus();
    },
    phoneMask(value: string): string {
      let newValue = value;
      var x = null;

      if (value.length <= 10) {
        x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      }

      if (x) {
        newValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      }

      return newValue;
    },
    AmountFormat(value: string): string {
      // let newValue = value;
      return '$'+value.replace(/\d(?=(\d{3}))/g, '$&,');
    },
    toggleActiveTextbox(): void {
      if (this.activeTextbox === 'playtextbox') {
        this.activeTextbox = 'amounttextbox';
      } else {
        this.activeTextbox = 'playtextbox';
      }
      this.focusActiveTextbox();
    },
    setFocusTo(name: string): void {
      this.activeTextbox = name
      this.focusActiveTextbox();
    },
    phoneIsValid() {
      const amount = parseInt(this.amountText);
      if (this.phoneText.length >= 10 || this.phoneText.length <= 15  ) {
        return true;
      }
      return false;
    },
    buildRecharge() {
      return new Promise((res, reject) => {
        try {
          const amount = parseInt(this.amountText);
          const recharge : Recharge = {
            phone_number: this.phoneText,
            amount,
            operator_id: this.selectedOperator.id,
            percentage: this.selectedItem,
          };

          res(recharge)
        } catch (err) {
          reject(err)
        }
      })
    },
    animateLotteryButton() {
      if(timeoutKey){
        clearTimeout(timeoutKey);
      }
        this.lotteryAnimated = true;
      timeoutKey =  setTimeout(() => {
            this.lotteryAnimated = false;
        }, 4000);
    }
  },
})
